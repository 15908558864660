.wrapper {
  margin: 8px auto;
}

.userName {
  font-size: 0.7em;
  font-weight: 900;
}

.message {
  font-size: 0.9em;
}