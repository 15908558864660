.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: none;
}
.wrapper[data-active="true"] {
  display: block;
}
.merchCover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  width: 200px;
  background-color: black;
  color: white;
  outline: none;
  border: none;
  font-size: 1.2em;
}

.controls {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100px;
}