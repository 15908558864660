$turquoise: #5BA294;
$turquoise-translucent: rgba(91, 162, 148, 0.5);
$orange: #FF9116;

.wrapper {
  position: fixed;
  bottom: 60px;
  right: 30px;
  font-family: 'Courier New', Courier, monospace;
  font-style: normal;
  z-index: 1;
}

.signInWrapper {
  width: 70%;

  input {
    flex: 1 1 auto;
    font-size: 1em;
    border: none;
    outline: none;
    padding: 8px;
    color: $turquoise;
    background: transparent;
    border: 2px solid $turquoise;
    font-family: 'Courier New', Courier, monospace;
    font-style: normal;
  }
}

.messageInput {
  width: 95%;
  border: 2px solid $turquoise;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  input {
    flex: 1 1 auto;
    font-size: 1em;
    border: none;
    outline: none;
    padding: 8px;
    color: $turquoise;
    background: transparent;
    font-family: 'Courier New', Courier, monospace;
    font-style: normal;
  }

  button {
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    color: white;
    flex: 0 0 50px;
    padding: 9px;
    padding: 2px;

    svg {
      height: 2em;
      fill: $turquoise;
    }
  }
}