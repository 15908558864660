.button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: turquoise;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  opacity: 1;
  transition: all 300ms ease;

  svg {
    fill: white;
    height: 2em;
    pointer-events: none;
  }
}

.button[data-active="true"] {
  opacity: 0;
  pointer-events: none;
  transition: all 300ms ease;
}