
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  color: white;
  
}
.loadingCover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  background-image: url('https://d1kbyrowkeh36j.cloudfront.net/images/welcome-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 99;
}
.loadingCover[data-ready="true"] {
  // background-image: none;
  z-index: -99;
  opacity: 0;
  transition: opacity 500ms ease, z-index 0ms 500ms;
}

.content {
  max-width: 500px;
}
@media (max-width: 800px) {
  .content {
    max-width: 95%;
  }
}

.welcomeTitle {
  max-width: 100%;
}
@media (max-width: 800px) {
  .welcomeTitle {
    font-size: 4.2em;
  }
}

.welcomeSubtitle {
  font-size: 2.5em;
  line-height: 1em;
  font-weight: 900;
}
@media (max-width: 800px) {
  .welcomeSubtitle {
    font-size: 1.9em;
  }
}

p {
  font-size: 1.2em;
}
@media (max-width: 800px) {
  p {
    font-size: 1.1em;
  }
}

.enterCta {
  position: relative;
}

.modelLoadingCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.loadingContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 0 auto;

  p {
    margin-bottom: 8px;
    width: 100%;
    font-size: 1.3em;
  }

  button {
    margin-top: 20px;
    font-size: 1.5em;
  }
}

@media (max-height: 680px) {

  .loadingContent{
    button {
      font-size: 0.9em;
      padding: 12px;
      margin-top: 6px;
    }
    p {
      font-size: 1.2em;
    }
  }
}

@media (max-height: 560px) {
  .loadingContent{
    button {
      font-size: 0.8em;
      padding: 10px;
      margin-top: 6px;
    }
    p {
      font-size: 0.9em;
    }
  }
}

.loadingBar {
  position: relative;
  border: 2px solid white;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  user-select: none;
  background-color: rgba(255,255,255,0.5);
}

.loadingBar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: white;
  animation: loading 4.5s ease forwards;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

@media (max-height: 560px) {
  .loadingBar {
    margin-top: 10px;
    padding: 8px;
  }
  .loadingText {
    font-size: 0.8em;
  }
}

.loadingText {
  font-size: 0.8em;
  position: relative;
  color: #5BA294;
}