$turquoise: #5BA294;
$turquoise-rgba: rgba(91, 162, 148, 1);
$orange: #FF9116;

body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
  font-weight: 400;
  font-style: italic;
  font-family: priori-sans, sans-serif;
  font-size: 1.1em;
  letter-spacing: 1px;
}

h1, h2, h3, h4, h5, h6, .title {
  font-weight: 700;
  font-style: normal;
}

a {
  text-decoration: none;
}

button {
  font-weight: 400;
  font-style: italic;
  font-family: priori-sans, sans-serif;
  cursor: pointer;
}

.btn {
  border: none;
  outline: none;
  padding: 8px 16px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
}

.btn--block {
  display: block;
  width: 100%;
}

.btn--primary {
  background-color: $turquoise;
  color: white;
  font-size: 1.2em;
}
.btn--black {
  background-color: black;
  color: white;
  font-size: 1.2em;
}

input {
  font-weight: 400;
  font-style: italic;
  font-family: priori-sans, sans-serif;
}

.input-row {
  margin: 10px auto;

  input {
    width: 100%;
    box-sizing: border-box;
  }
}

.video3D {
  // z-index: -99;
  position: fixed;
  top: -100vh;
  left: -100vw;
  pointer-events: none;
}

.password-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.3em;

  form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    // height: 300px;
    width: 300px;
  }

  button, input {
    width: 100%;
    box-sizing: border-box;
  }

  button {
    background-color: black;
    color: white;
    border: none;
    outline: none;
  }
  p {
    font-size: 1em;
  }
}

.wa-service-btn-apple-music, .wa-service-btn-spotify {
  background-color: white !important;
  border: 1px solid black !important;
  color: black !important;

  svg {
    fill: black !important;
  }
}


.cookie-footer {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;

  a {
    margin: 0 8px;
    color: white;
  }
}

.link-footer {
  position: fixed;
  bottom: 90px;
  left: 8px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  
  a {
    margin: 0px 8px;
    color: white;
    font-style: normal;
    text-decoration: underline;
  }
}
.link-footer[data-active="true"] {
  display: block;
}
.link-footer {
  display: none;
}
@media (max-width: 800px) {
  .link-footer {
    bottom: 140px;
    width: 50%;
    z-index: 0;

    span {
      width: 100%;
    }

    a {
      margin: 8px 0px;
      width: 100%;
      color: white;
      font-style: normal;
      text-decoration: underline;
    }
  }
}

.premier-trigger {
  position: fixed;
  top: 0;
  left: 0;
}