$turquoise: #5BA294;
$turquoise-translucent: rgba(91, 162, 148, 0.3);
$orange: #FF9116;

.wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  color: $turquoise;
  box-sizing: border-box;
  padding: 30px 20px 20px 20px;
  width: 350px;
  height: 400px;
  transform: scale(0);
  opacity: 0;
  transition: all 300ms ease;
  transform-origin: bottom right;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.wrapper[data-active="true"] {
  transform: scale(1);
  opacity: 1;
  transition: all 300ms ease;
  pointer-events: all;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.messages {
  // border: 1px solid $turquoise;
  position: relative;
  width: 90%;
  height: 400px;
  overflow-y: scroll;
  padding: 10px;
  box-sizing: border-box;
}

.messagesInner {
  overflow:   auto;
  position:   absolute;
  bottom:     0;
  left: 0;
  width: 100%;
  max-height: 400px;
  box-sizing: border-box;
}

.close {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
}