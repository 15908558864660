.webglWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
}
.webglWrapper[data-role="3d"][data-pointer="true"] {
  pointer-events: all;
}
.webglWrapper[data-role="3d"][data-cursorpointer="true"] {
  cursor: pointer;
}

.webglWrapper[data-role="3d"][data-pointer="false"] {
  pointer-events: none;
}
.webglWrapper[data-role="3d"][data-cursorpointer="false"] {
  cursor: auto;
}

.startBtn {
  position: absolute;
  top: 0;
  left: 0;
}

.instructions {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  text-align: center;
  color: white;
  opacity: 0;
  pointer-events: none;
}
.instructions[data-active="true"] {
  opacity: 1;
}

.videoWallBack {
  position: absolute;
  bottom: 150px;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
}

@media (max-width: 800px) {
  .videoWallBack {
    bottom: 190px;
  }
}